import React from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import Event from './Event';
import eventPropType from './propTypes';

export default class Day extends React.Component {
  constructor(props) {
    super(props);

    this.renderEvents = this.renderEvents.bind(this);
  }

  renderEvents() {
    const { events } = this.props;

    return events.map((event) => {
      return <Event event={event} key={event.id} />;
    });
  }

  render() {
    const { date } = this.props;
    const style = {
      gridColumnStart: dateFormat(date, 'N'),
    };

    return (
      <div
        className="day"
        style={style}
      >
        <h2>
          <span>{dateFormat(date, 'dddd ')}</span>
          {dateFormat(date, 'dS mmmm')}
        </h2>
        {this.renderEvents()}
      </div>
    );
  }
}

Day.propTypes = {
  events: PropTypes.arrayOf(eventPropType).isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};
