import React from 'react';
import dateFormat from 'dateformat';
import eventPropType from './propTypes';

export default class Event extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      moreInfo: false,
    };

    this.toggleMoreInfo = this.toggleMoreInfo.bind(this);
  }

  toggleMoreInfo() {
    this.setState((prevState) => {
      return {
        moreInfo: !prevState.moreInfo,
      };
    });
  }

  render() {
    const { event } = this.props;
    const title = `${event.movie.title}${event.is3D ? ': 3D' : ''}`;

    const start = new Date(event.start);
    const startString = dateFormat(start, 'HH:MM');

    const end = new Date(event.end);
    const endString = dateFormat(end, 'HH:MM');

    const { moreInfo } = this.state;
    return (
      <div className="event">
        <div
          className="event_summary"
        >
          <button
            type="button"
            onClick={this.toggleMoreInfo}
          >
            {title}
            &nbsp;
            <span>
              {`(${event.movie.rating})`}
            </span>
          </button>
          <a
            href={event.bookingLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {startString}
          </a>
        </div>
        {moreInfo ? (
          <div className="more_info">
            <span>{event.screen}</span>
            <span>{`Ends ${endString}`}</span>
            { event.movie.youtube ? (
              <div className="youtube_embed">
                <iframe
                  title={event.movie.title}
                  src={`https://www.youtube.com/embed/${event.movie.youtube}`}
                  frameBorder="0"
                  allow="autoplay; encrypted-media; fullscreen"
                  allowFullScreen
                />
              </div>
            ) : null }
          </div>
        ) : null}
      </div>
    );
  }
}

Event.propTypes = {
  event: eventPropType.isRequired,
};
