import bugsnag from 'bugsnag-js';
import React from 'react';
import ReactDOM from 'react-dom';
import createPlugin from 'bugsnag-react';
import App from './App';
import './style.css';

const bugsnagClient = bugsnag('a3178bf0ebfd12096b5239356512e658');
const ErrorBoundary = bugsnagClient.use(createPlugin(React));

const MOVIES_DATA = './movies.json';

const XML_DATA = new XMLHttpRequest();
XML_DATA.open('GET', MOVIES_DATA);
XML_DATA.send(null);

XML_DATA.onload = (e) => { /* Render the app */
  const data = JSON.parse(e.target.response);
  ReactDOM.hydrate(
    <ErrorBoundary>
      <App movies={data} />
    </ErrorBoundary>,
    document.getElementById('timetable')
  );
};
