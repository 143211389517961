import React from 'react';
import PropTypes from 'prop-types';
import eventPropType from './propTypes';

export default class Movies extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle(event) {
    const { setMovie } = this.props;
    setMovie(event.target.value);
  }

  render() {
    const { movies } = this.props;
    const sortedMovies = movies.sort((a, b) => {
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }

      return -1;
    });

    const options = sortedMovies.map((movie) => {
      return <option key={movie.title} value={movie.title}>{movie.title}</option>;
    });

    return (
      <select
        name="showMovie"
        onChange={this.toggle}
      >
        <option key={0} value="">Filter by Movie</option>
        {options}
      </select>
    );
  }
}

Movies.propTypes = {
  movies: PropTypes.arrayOf(eventPropType).isRequired,
  setMovie: PropTypes.func.isRequired,
};
