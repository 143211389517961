import React from 'react';
import PropTypes from 'prop-types';
import Day from './Day';
import eventPropType from './propTypes';

const ONE_DAY = 86400000;

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.renderDays = this.renderDays.bind(this);
    this.getDays = this.getDays.bind(this);
  }

  getDays() {
    const { events } = this.props;
    const days = [];
    events.forEach((event) => {
      const dateId = parseInt(event.start / ONE_DAY, 10);

      if (typeof days[dateId] === 'undefined') {
        days[dateId] = [];
      }
      days[dateId].push(event);
    });

    Object.keys(days).forEach((day) => {
      days[day].sort((a, b) => {
        return a.start - b.start;
      });
    });

    return days;
  }

  renderDays() {
    const days = this.getDays();
    return Object.keys(days).map((date) => {
      return (
        <Day
          events={days[date]}
          date={new Date(date * ONE_DAY)}
          key={date}
        />
      );
    });
  }

  render() {
    return (
      <div className="calendar">
        <div className="dayHeading">Monday</div>
        <div className="dayHeading">Tuesday</div>
        <div className="dayHeading">Wednesday</div>
        <div className="dayHeading">Thursday</div>
        <div className="dayHeading">Friday</div>
        <div className="dayHeading">Saturday</div>
        <div className="dayHeading">Sunday</div>
        {this.renderDays()}
      </div>
    );
  }
}

Calendar.propTypes = {
  events: PropTypes.arrayOf(eventPropType).isRequired,
};
