import React from 'react';
import PropTypes from 'prop-types';
import Movies from './Movies';
import Calendar from './Calendar';
import eventPropType from './propTypes';

export default class App extends React.Component {
  static getEventsForMovie(movie) {
    const movieData = {
      title: movie.title,
      rating: movie.rating,
      description: movie.description,
      youtube: movie.youtube,
    };

    return movie.performances.map((performance) => {
      const event = performance;
      event.movie = movieData;

      return event;
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      showMovie: null,
    };

    this.setMovie = this.setMovie.bind(this);
    this.getEvents = this.getEvents.bind(this);
  }

  getEvents() {
    const { showMovie } = this.state;
    const { movies } = this.props;

    if (showMovie) {
      const movieToShow = movies.find((movie) => {
        return movie.title === showMovie;
      });

      return App.getEventsForMovie(movieToShow);
    }

    return movies.map(App.getEventsForMovie).reduce((a, b) => {
      return a.concat(b);
    }, []);
  }

  setMovie(title) {
    this.setState({
      showMovie: title,
    });
  }

  render() {
    const events = this.getEvents();
    const { showMovie } = this.state;
    const { movies } = this.props;

    return (
      <div>
        <Movies
          movies={movies}
          setMovie={this.setMovie}
          showMovie={showMovie}
        />
        <Calendar events={events} />
      </div>
    );
  }
}

App.propTypes = {
  movies: PropTypes.arrayOf(eventPropType).isRequired,
};
