import PropTypes from 'prop-types';

export default PropTypes.shape({
  movie: PropTypes.shape({
    title: PropTypes.string,
    rating: PropTypes.string,
    description: PropTypes.string,
    youtube: PropTypes.string,
  }),
  id: PropTypes.string,
  start: PropTypes.number,
  end: PropTypes.number,
  screen: PropTypes.string,
  bookingLink: PropTypes.string,
  is3D: PropTypes.bool,
});
